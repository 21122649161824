export const asthmaStatus = {
    1: {label: "正常", color: "normal-font", bg: "normal-bg", rgb: "#2EBF69"},
    2: {label: "警告", color: "warning-font", bg: "warning-bg", rgb: "#E8D43F"},
    3: {label: "危险", color: "danger-font", bg: "danger-bg", rgb: "#E8483F"}
  }
 export const hinderAsthmaStatus = {
    1: {label: "正常", color: "normal-font", bg: "normal-bg", rgb: "#2EBF69"},
    2: {label: "轻度", color: "mild-font", bg: "mild-bg", rgb: "rgba(144, 216, 77, 1)"},
    3: {label: "中度", color: "warning-font", bg: "warning-bg", rgb: "#E8D43F"},
    4: {label: "重度", color: "danger-font", bg: "danger-bg", rgb: "#E8483F"},
    5: {label: "极重度", color: "danger2-font", bg: "danger2-bg", rgb: "rgba(139, 17, 10, 1)"}
  }

 export const getAsthmaStatusValue = (value: number) => {
    if (value >= 80 && value <= 100) {
        return 1;
    } 
    if (value >= 60 && value <= 79) {
        return 2;
    } 
    return 3;
 }
 export const getHinderAsthmaStatus = (value: number) => {
    if (value >= 80 && value <= 100) {
        return 1;
    } 
    if (value >= 60 && value <= 79) {
        return 2;
    } 
    if (value >= 30 && value <= 59) {
        return 3;
    } 
    return 4;
 }

 export const  getAsthmaStatusSetting = (resultKey: number) => {
    return asthmaStatus[resultKey as keyof typeof asthmaStatus]
 }
 export const  getHinderAsthmaStatusSetting = (resultKey: number) => {
    return hinderAsthmaStatus[resultKey as keyof typeof hinderAsthmaStatus]
 }