
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getRegionData } from "@/utils/cityCode";

@Component
export default class RegionData extends Vue {
  regionData: Array<any> = [];

  mounted() {
    this.setRegionData();
  }

  setRegionData() {
    return getRegionData().then((data: Array<any>) => {
        this.regionData = data;
        return data;
    })
  }
}