import { service } from '@/ajax/request'

//获取所有设备类型列表
export const getDeviceTypeAllList = (params: any) => (
  service({
    url: '/device/deviceType/getDeviceTypeAllList',
    method: 'GET',
    type: 'form',
    params
  })
)


//获取一级设备类型列表
export const getDeviceTypeList = (params: any) => (
  service({
    url: '/device/deviceType/getDeviceTypeList',
    method: 'GET',
    type: 'form',
    params
  })
)

//获取二级设备类型列表
export const getDeviceSubTypeList = (params: any) => (
  service({
    url: '/device/deviceType/getDeviceSubTypeList',
    method: 'GET',
    type: 'form',
    params
  })
)

//获取二级设备类型列表getDeviceTypeByParentId
export const getDeviceTypeByParentId = (params: any) => (
  service({
    url: '/device/deviceType/getDeviceEquipModelList',
    method: 'GET',
    type: 'form',
    params
  })
)



//添加一级设备类型 deviceTypeName
export const save = (params: any) => (
  service({
    url: '/device/deviceType/save',
    method: 'POST',
    type: 'form',
    params
  })
)

//添加二级设备类型
// createTime	创建时间默认生成		false	
// string(date-time)
// deviceName	设备类型名称		false	
// string
// deviceTypeId	设备类型id 默认0		false	
// integer(int32)
// id			false	
// integer(int32)
// remark	备注		false	
// string
// runtime	运行时间		false	
// integer(int32)
// updateTime	更新时间默认生成		false	
// string(date-time)
export const saveSubDeviceType = (params: any) => (
  service({
    url: '/device/deviceType/saveSubDeviceType',
    method: 'POST',
    params
  })
)


// 修改一级设备类型
// deviceTypeName	deviceTypeName	query	true	
// string
// id	id	path	true	
// integer(int32)
export const updateDiscountById = (params:any) => (
  service({
    url: `/device/deviceType/update/${params.id}`,
    method: 'POST',
    params
  })
)

// 修改二级设备类型
// createTime	创建时间默认生成		false	
// string(date-time)
// deviceName	设备类型名称		false	
// string
// deviceTypeId	设备类型id 默认0		false	
// integer(int32)
// id			false	
// integer(int32)
// remark	备注		false	
// string
// runtime	运行时间		false	
// integer(int32)
// updateTime	更新时间默认生成		false	
// string(date-time)
export const updateSubDeviceType = (params:any) => (
  service({
    url: `/device/deviceType/updateSubDeviceType/${params.id}`,
    method: 'POST',
    params
  })
)

// 设备类型一级分类
export const addDeviceType = (params:any) => (
  service({
    url: `/DeviceClassType/deviceClassType/addDeviceType`,
    method: 'POST',
    params
  })
)

// 设备类型二级分类
export const addDeviceClass = (params:any) => (
  service({
    url: `/DeviceClassType/deviceClassType/addDeviceClass`,
    method: 'POST',
    params
  })
)

export const deviceClassList = (params:any) => (
  service({
    url: `/DeviceClassType/deviceClassType/getAllDeviceTypeList`,
    method: 'GET',
    params
  })
)

export const getMainDeviceTypeList = (params:any) => (
  service({
    url: `/device/deviceType/getMainDeviceTypeList`,
    method: 'GET',
    params
  })
)
export const getSubDeviceTypeList = (params) => (
  service({
    url: `/device/deviceType/getSubDeviceTypeList`,
    method: 'GET',
    params
  })
)
export const saveOrUpdateDeviceType = (params) => (
  service({
    url: `/device/deviceType/saveOrUpdate`,
    method: 'POST',
    params
  })
)
export const getDeviceByName = (params) => (
  service({
    url: `/device/deviceType/getDeviceByName`,
    method: 'POST',
    params
  })
)



