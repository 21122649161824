
import { Component, Vue, Mixins, Prop, Watch } from "vue-property-decorator";
import loading from "@/mixins/loading";
import {
  getRelationList
} from "@/api/userMember";

@Component
export default class RelationList extends Vue {
  @Prop({ default: "" }) userId?: string;
  relations = [];
  relationArray = [
    { label: "家人", value: 1, color: "#E74C3C" },
    { label: "亲戚", value: 2, color: "#E67E22" },
    { label: "兄弟", value: 3, color: "#3498D8" },
    { label: "姐妹", value: 4, color: "#F1C40F" },
    { label: "朋友", value: 5, color: "#1ABC9C" },
  ]
  loading = false;

  get relationList() {
    return this.relations.map((R: any) => {
      const { relation } = R;
      return { ...R, ...this.relationArray[relation - 1] }
    })
  }

  getAge(age: number | string) {
    if (age == "0" || age == 0) return 0 + "岁";
    if (!age) return "未知";
    return age + "岁";
  }

  mounted() {
    this.relations = [];
    this.loading = true;
    getRelationList({ id: this.userId }).then((res) => {
      this.relations = res.data.allList;
    }).finally(() => {
      this.loading = false;
    })
  }
}
