/**
 * 输入值过滤，只能输入数字，且小数点保留后两位
 * @param {string | number} value 
 */
const inputPrice = (value: string | number, type = 'price') => {
  let val: any = "" + value;
  if (type == 'price') {
    val = val
      .replace(/[^\d^\.]+/g, "")
      .replace(/^0(\d)/g, "$1")
      .replace(/^\./g, "0.")
      .match(/^\d*(\.?\d{0,2})/g)[0] || "";
    return val
  } else if (type == 'number') {
    // 输入数字，第一位可以为0
    // 多个数字第一位不能为0
    val = val
      .replace(/[\D]+/g, "")
      .replace(/^0(\d)/g, "$1")
    return Number(val)
  } else if (type == '4') {
    // 保留四位小数
    val = val
      .replace(/[^\d^\.]+/g, "")
      .replace(/^0(\d)/g, "$1")
      .replace(/^\./g, "0.")
      .match(/^\d*(\.?\d{0,4})/g)[0] || "";
    return val
  }
  return val
}

export default inputPrice