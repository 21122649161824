import { service } from '@/ajax/request'


// nickname	昵称（显示名称）	query	false	
// string
// pageNum	pageNum,示例值(1)	query	false	
// integer(int32)
// pageSize	pageSize,示例值(5)	query	false	
// integer(int32)
// phone	手机号码	query	false	
// string
// storeName	最近去的门店名
export const userList = (params: any) => (
  service({
    url: '/user/userMember/list',
    method: 'POST',
    params,
    type: "string"
  })
)

export const updateUser = (params: any) => (
  service({
    url: '/user/userMember/update',
    method: 'POST',
    type: 'form',
    params
  })
)

export const getReportData = (params: any) => (
  service({
    url: '/user/memberHealth/getReportData',
    method: 'GET',
    params,
    type: 'form'
  })
)

export const getHealth = (params: any) => (
  service({
    url: '/user/memberHealth/health',
    method: 'GET',
    params,
    type: 'form'
  })
)

export const getTongueList = (params: any) => (
  service({
    url: `/user/tongue/getTongueList/${params.userId}`,
    method: 'GET',
    params,
    type: 'form'
  })
)


export const physiqueList = (params: any) => (
  service({
    url: `/user/userMemberDetection/physiqueList`,
    method: 'GET',
    params,
    type: 'form'
  })
)
export const getUserCenterInfo = (params: any) => (
  service({
    url: `/user/userMember/userCenterInfo/${params.id}`,
    method: 'GET',
    type: 'form'
  })
)
export const getRelationList = (params: any) => (
  service({
    url: `/user/userMember/getRelationList/${params.id}`,
    method: 'GET',
    type: 'form',
    params
  })
)
export const getUserInfo = (params: any) => (
 service({
    url: `/user/userMember/getUserInfo/${params.id}`,
    method: 'GET',
    type: 'form',
    params
  })
)
export const editUserInfo = (params: any) => (
 service({
    url: `/user/userMember/editUserInfo/${params.id}`,
    method: 'POST',
    params
  })
)

export const getUserList = (params: any) => (
  service({
    url: '/user/userMember/getUserList',
    method: 'GET',
    params,
    type: 'form'
  })
)

export const userMemberDetection = (params: any) => (
  service({
    url: '/user/userMemberDetection/pef',
    method: 'GET',
    params,
    type: 'form'
  })
)
export const getHealthType = (params: any) => (
  service({
    url: '/user/userMember/getHealthType',
    method: 'GET',
    params,
    type: 'form'
  })
)
export const userOrderInfo = (params: any) => (
  service({
    url: '/user/userMember/userOrderInfo',
    method: 'POST',
    params,
  })
)
export const getSaasStore = (id) => (
  service({
    url: `/user/userMember/getSaasStore/${id}`,
    method: 'GET',
    type: 'form'
  })
)




