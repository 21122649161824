<template>
    <div class="consume-record-box">
        <el-select @change="storeIdChange" v-model="queryForm.storeId" placeholder="请选择">
            <el-option
            v-for="item in storeOptions"
            :key="item.storeId"
            :label="item.storeName"
            :value="item.storeId">
            </el-option>
        </el-select>
        <el-date-picker
            class="date-picker"
            style="margin-left: 10px;"
            v-model="queryForm.time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            @change="timeChange"
            :picker-options="pickerOptions"
        >
        </el-date-picker>
        <div class="record-one" v-for="item in list" :key="item.id">
            <div class="record-one__top">
                <span>{{item.order_date}}</span>
                <span>{{item.id}}</span>
            </div>
            <div class="record-one__center">
                <span>{{item.behavior_type_name}}({{item.storeName}})</span>
                <span>{{item.orderAmountStr}}</span>
            </div>
            <div class="record-one__bottom">
                <span v-if="item.balance">存储余额（元）：{{item.balanceStr}}</span>
                <span v-if="item.giftBalance">赠送余额（元）：{{item.giftBalanceStr}}</span>
            </div>
        </div>
    </div>
</template>
<script>
import { Component, Vue, Mixins, Prop, Watch, Emit } from "vue-property-decorator";

import dayjs from "dayjs";

import { getSaasStore, userOrderInfo } from "@/api/userMember";
import { toYuanFixed } from "@/utils/common";

@Component
export default class ConsumeRecord extends Vue {
    @Prop() userId;
    @Prop() secondActiveName;

    list = [];

    queryForm = {
        storeId: "",
        time: [],
    }

    storeOptions = [];

    pickerOptions = {
        disabledDate: this.disabledDateFn
    };

    disabledDateFn(time) {
        if (!this.queryForm.time?.length) return false;
        const [ start, end ] = this.queryForm.time;
        const startDateMin =  dayjs(end).subtract(6, "month").startOf("day");
        const endDateMax = dayjs(start).add(6, "month").startOf("day");
        const _time = dayjs(time).startOf("day");;
        if (_time.isBefore(startDateMin) || _time.isAfter(endDateMax)) return true;
        return false;
    }

    @Watch("secondActiveName", { immediate: true })
    getData(value) {
        console.log("value", value)
        if (value == 3) {
            this.getSaasStoreList();
        }
    }

    mounted() {
        const today = dayjs().toDate();
        const beforeMonth = dayjs().subtract(1, "month").toDate();
        this.queryForm.time = [beforeMonth, today];
        // this.getSaasStoreList();
    }
    getSaasStoreList() {
        getSaasStore(this.userId).then((res) => {
            this.storeOptions = res.data || [];
            this.queryForm.storeId = res.data[0]?.storeId;
            this.getRecordsList(res.data[0]?.storeId)
        })
    }
    getRecordsList(storeId) {
        const params = {
            id: this.userId,
            storeId: storeId,
            beginDate: this.queryForm.time ? dayjs(this.queryForm.time[0]).format("YYYY-MM-DD") : undefined,
            endDate: this.queryForm.time ? dayjs(this.queryForm.time[1]).format("YYYY-MM-DD") : undefined,
        }
        this.list = [];
        userOrderInfo(params).then((res) => {
            this.list = res.data;
            this.list?.forEach((el) => {
                el.orderAmount = Number(el.orderAmount ?? 0);
                el.balanceStr = toYuanFixed(el.balance)
                el.giftBalanceStr = toYuanFixed(el.giftBalance)
                el.orderAmountStr = toYuanFixed(el.orderAmount)
            })
        })
    }
    timeChange($event) {
        if (this.secondActiveName != 3) return;
        this.getRecordsList(this.queryForm.storeId)
    }
    storeIdChange() {
        if (this.secondActiveName != 3) return;
        this.getRecordsList(this.queryForm.storeId)
    }
    
}
</script>

<style lang="scss" scoped>
.record-one{
    border-radius: 5px;
    overflow: hidden;
    margin-top: 15px;
}
.record-one__top{
    display: flex;
    justify-content: space-between;
    height: 50px;
    align-items: center;
    background: #F8FAFD;
    color: #54575A;
    padding: 0 10px;
}
.record-one__center{
    font-size: 22px;
    height: 50px;
    line-height: 50px;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
}
.record-one__bottom{
    font-size: 14px;
    color: #999;
    border-bottom: 1px solid #efefef;
    padding: 0 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
}
.date-picker{
    width: 250px;
}
</style>